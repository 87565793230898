import BackordersTable from "../components/Catalogue/BackordersTable";

function BackOrders() {
    return (
        <div>
            <h1 className="text-2xl py-4">Invoices</h1>
            <BackordersTable />
        </div>
    );
}

export default BackOrders;