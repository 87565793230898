import VendorsTable from "../components/Catalogue/VendorsTable";

function Vendors() {
    return (
        <div>
            <h1 className="text-2xl py-4">Vendors</h1>
            <VendorsTable />
        </div>
    );
}

export default Vendors;