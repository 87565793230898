import StatementsTable from "../components/Catalogue/StatementsTable";
function Statements() {
    return (
        <div>
            <h1 className="text-2xl py-4">Customer Statements</h1>
            <StatementsTable />
        </div>
    );
}

export default Statements;