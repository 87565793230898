export const VENDORS_COLUMNS = [
    {
        header: 'Vendor',
        accessorKey: 'SUPDES',
        enableSorting: true,
    },
    {
        header: 'Address',
        accessorKey: 'ADDRESS',
        enableColumnFilter: false,
        enableSorting: false,
    },
    {
        header: "District",
        accessorKey: 'STATEA',
        enableColumnFilter: false,
        enableSorting: false,
    },
    {
        header: "Country",
        accessorKey: 'COUNTRYNAME',
        enableColumnFilter: false,
        enableSorting: false,
    },
    {
        header: "Phone",
        accessorKey: 'PHONE',
        enableColumnFilter: false,
        enableSorting: false,
    },
];