import InvoicesTable from "../components/Catalogue/InvoicesTable";
function Invoices() {
    return (
        <div>
            <h1 className="text-2xl py-4">Invoices</h1>
            <InvoicesTable />
        </div>
    );
}

export default Invoices;