export const INVOICE_COLUMNS = [
    {
        header: 'Invoice Num',
        accessorKey: 'IVNUM',
        enableSorting: true,
    },
    {
        header: "Date",
        accessorKey: 'IVDATE',
        enableColumnFilter: false

    },
];